import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

export default class ColoredScrollbars extends React.PureComponent {

    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = {top: 0};
        this.handleUpdate = this.handleUpdate.bind(this);
        this.renderView = this.renderView.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
    }

    handleUpdate(values) {
        const {top} = values;
        this.setState({top});
    }


    renderView({style, ...props}) {
        const {top} = this.state;
        const viewStyle = {
            padding: 15,
            backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
            color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
        };
        return (
            <div
                className="box"
                style={{...style, ...viewStyle}}
                {...props}
            />
        );
    }

    renderThumb({style, ...props}) {
        const {top} = this.state;
        const color = `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`
        const thumbStyle = {
            backgroundColor: color,
            //backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
            borderRadius: "5px",
            boxShadow: `0 0 5px ${color}, 0 0 10px white`,
        };
        return (
            <div
                style={{...style, ...thumbStyle}}
                {...props}
            />
        );
    }

    render() {
        return (
            <Scrollbars
                autoHide
                // Hide delay in ms
                autoHideTimeout={1000}
                // Duration for hide animation in ms.
                autoHideDuration={200}
                renderThumbVertical={this.renderThumb}
                onUpdate={this.handleUpdate}
                {...this.props}
            />
        );
    }
}